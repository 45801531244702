import React, { useMemo } from "react"
import { Script, ScriptStrategy } from "gatsby"

import { dataStore, pageStore, locationStore } from "state/store-zustand";

//import RootElement from 'components/RootElement'

// Sass for Vibemap.com
import "./src/styles/index.scss"
import "./src/components/header/header.scss"
import "./src/components/footer/footer.scss"
import 'react-loading-skeleton/dist/skeleton.css'

export const disableCorePrefetching = () => true;

/*
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
    `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
*/

export const wrapPageElement = ({ element, props }) => {
  const GTM_ID = process.env.GATSBY_GOOGLE_TAG_MANAGER_ID
  const GA_ID = process.env.GATSBY_GOOGLE_ANALYTICS_ID
  const FB_ID = process.env.GATSBY_FB_PIXEL_ID
  const GA4_ID = 'G-M35M4ZG97H'

  const {
    city,
    data,
    pageContext,
    path,
  } = props

  // Include any data from the pageContext in the templateContext
  const { slug, events, eventsRecurring, posts } = pageContext
  const initialEvents = useMemo(() => events && events.length > 0 ? events : [], [events])
  const allEvents = initialEvents.concat(eventsRecurring)
  const initialPosts = useMemo(() => posts && posts.length > 0 ? posts : [], [posts])

  // TODO: Move this to a util
  // Initialize the global state with any data from the pageContext
  const selectedCity = useMemo(() => pageContext.cityDetails, [pageContext.cityDetails])
  const setCurrentCity = locationStore((state) => state.setCurrentCity)
  const setHasLocation = locationStore((state) => state.setHasLocation)
  const setTryLocation = locationStore((state) => state.setTryLocation)
  const tryLocation = path == 'add-event' || path == 'add-place'

  const setEvents = dataStore((state) => state.setEvents)
  const setPosts = dataStore((state) => state.setPosts)

  const isActive = pageStore((state) => state.isActive)
  const setIsActive = pageStore((state) => state.setIsActive)
  const setIsClient = pageStore((state) => state.setIsClient)
  const setPageHasMap = pageStore((state) => state.setPageHasMap)
  const isLocationPage = ['neighborhoods', 'cities'].some(page => path.includes(page))
  const shouldSettURLParams = !isLocationPage
  const pageHasMap = path.includes('map')

  const handleScroll = () => {
    if (!isActive) setIsActive(true)
  }

  if (selectedCity) {
    setCurrentCity(selectedCity)
    setHasLocation(true)
  }

  if (tryLocation) {
    setTryLocation(true)
  }

  if (pageHasMap) {
    setPageHasMap(true)
  }

  if (isActive) {
    setIsActive(true)
  }

  if (initialEvents) {
    setEvents(initialEvents)
  }

  if (initialPosts) {
    setPosts(initialPosts)
  }

  return (
    <>
      {element}

      <Script
        id="gtm-script"
        strategy={ScriptStrategy.idle}
        onLoad={() => console.log('success')}
        onError={() => console.log('sadness')}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.defer=true;j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');`
        }} />
      <Script
        id="g4-analytics"
        strategy={ScriptStrategy.idle}
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_ID}`} />
      <Script
        id="google-analytics-config"
        onLoad={() => console.log('success')}
        onError={() => console.log('sadness')}
        strategy={ScriptStrategy.idle}
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', '${GTM_ID}');
            gtag('config', '${GA4_ID}')
            gtag('config', '${GA_ID}')`
        }}
      />
      {/*
      <Script
        id="data-dog"
        strategy={ScriptStrategy.idle}
        onLoad={() => console.log('Data Dog loaded')}
        onError={() => console.log('Data Dog error')}
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,u,n,d) {
            h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
            d=o.createElement(u);d.async=1;d.defer=1;d.src=n
            n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
          })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
          DD_RUM.onReady(function() {
            DD_RUM.init({
              clientToken: 'pub470aec028df7c2faeea4fede06adcc93',
              applicationId: '70f59ae9-c574-489b-bf3c-0122ef9d4bd4',
              site: 'datadoghq.com',
              service: 'vibemap.com',

              // Specify a version number to identify the deployed version of your application in Datadog
              // version: '1.0.0',
              sampleRate: 100,
              premiumSampleRate: 100,
              trackInteractions: true,
              defaultPrivacyLevel: 'mask-user-input',
            });

            DD_RUM.startSessionReplayRecording();
          })
          `
        }} />
        <Script
          id="clarity"
          strategy={ScriptStrategy.idle}
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.defer=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "4d5n8qomud");`
          }}/>
      */}
    </>
  )
}

const addScript = (src, innerHTML) => {
  const script = document.createElement('script')
  script.type = "text/javascript"

  if (src) {
    script.src = src
  }

  // Optinoal inline scrip
  if (innerHTML) {
    script.innerHTML = innerHTML
  }

  document.body.appendChild(script)
  script.async = true
  script.defer = true
}

// Load GTM using this technique:
// https://shahid.pro/blog/2021/09/11/load-gtag-efficiently-in-gatsby/
const initGTM = () => {
    if (window.isGTMLoaded) {
    return false;
  }

  //console.log(`initGTM `, initGTM)
  //console.log(`GTM ID `, process.env.GATSBY_GOOGLE_TAG_MANAGER_ID);
  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.defer = true
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}`

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      //dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', `${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}`);
  }

  document.head.appendChild(script)
  window.isGTMLoaded = true
}


export const onClientEntry = () => {
  console.log('DEBUG: onClientEntry');
  // TODO: Are there any otehr scripts that should be loaded like this?
  if (process.env.NODE_ENV !== 'production') {
    console.log('Starting @welldone-software/why-did-you-render')
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      trackAllPureComponents: false
    })
  }
}

// Send page view events on route change
export const onRouteUpdate = ({ location }) => {
  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  //console.log(`DEBUG: onRouteUpdate `, pagePath)
  setTimeout(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', { page_path: pagePath })
    }
  }, 100)
}

let scriptsAdded = false
const loadScripts = (event) => {
  const scripts = [
    {
      name: `gatsby-plugin-instagram`,
      src: null,
      body: `
        window.gatsbyLoadInstagram = function() {
          var js = document.createElement('script');
          var firstScript = document.getElementsByTagName('script')[0];
          js.id = 'gatsby-plugin-instagram';
          js.src = 'https://instagram.com/embed.js';
          js.async = true;
          js.defer = true;
          firstScript.parentNode.insertBefore(js, firstScript);
          return true;
        }
      `
    }
  ]

  if (!scriptsAdded) {
    console.log('onInitialClientRender: add scripts here…')

    if (event) event.currentTarget.removeEventListener(event.type, loadScripts)

    //initGTM()

    scripts.forEach(script => {
      addScript(script.src, script.body)
    })

    scriptsAdded = true
  }
}

export const onInitialClientRender = () => {

  document.onreadystatechange = () => {
    if (document.readyState !== 'loading') {
      setTimeout(loadScripts, 4500)
    }
  }

  console.log('DEBUG: in inital render');

  document.addEventListener('scroll', loadScripts)
  document.addEventListener('mousemove', loadScripts)
  document.addEventListener('touchstart', loadScripts)
}