import { create } from 'zustand'

import isEqual from 'react-fast-compare'
import useMobileDetect from 'use-mobile-detect-hook'
import queryString from 'query-string'

import { window } from 'browser-monads'

// TODO: tanQuery (react-query) or getInitialProps might be a better option here.
import {
  activityCategories as allActivities,
  getAllBoundaries,
  getBoundary,
  cities as allCities,
  neighborhoods as allNeighborhoods,
  vibes as allVibes
} from 'vibemap-constants/dist/helpers'

import { getVibes } from 'vibemap-constants/dist/vibes.js'


// Default state used to seed the store and track URL params
export const defaultValues = {
  activities: [], // TODO: change to categories
  categories: [],
  cardOr: 'horizontal',
  cardStyle: 'list',
  cities: [],
  currentLocation: {
    latitude: 37.8125262,
    longitude: -122.3054825,
    fromUser: false,
    userRequested: false,
    centerpoint: [100, 30]
  },
  currentZoom: 12,
  dateRange: 'month',
  dateStart: undefined,
  dateEnd: undefined,
  debug: false,
  desc: false, // Combine with showDescription
  editorial_category: undefined,
  editorialCategory: null,
  embedded: false,
  filters: {
    category: [],
    cities: [],
    vibesets: [],
    vibes: [],
    embedded: null,
    searchTerm: null,
    theme: null
  },
  filterOptions: ['categories', 'vibes', 'cities'],
  getCity: null,
  fitBounds: true,
  fitMarkers: false,
  keepBounds: false,
  mapPos: 'right',
  mapTheme: 'vibemap',
  mapUpdated: false,
  minZoom: 8,
  numPlacesToShow: 50,
  numPlacesToStore: 400,
  placeLayout: 'both',
  searchRadius: 12,
  shouldShuffle: false,
  showAddress: false,
  showDescription: false,
  showNote: false,
  showTitle: false,
  showBoundary: true,
  showCats: true,
  showDateRange: false,
  showPopups: true,
  showSearch: true,
  showTags: false,
  showVibes: true,
  showFilters: true,
  showHeat: true,
  showLocations: false,
  showAs: 'map',
  showTransit: false,
  storyScroll: false,
  tags: [],
  theme: null,
  zoom: 12,
}

// 🔗 Routes and URL params
const page = typeof window !== `undefined` && window.location ?
  window.location.pathname :
  `/`

// Look up city in main list
// if not found, do an API call
export const getCity = async (key, field = 'slug') => {
  let city = allCities.find(result => result[field] === key)
  if (city?.location) {
    city.centerpoint = [city.location.longitude, city.location.latitude]
    city.location.fromUser = true
  }

  if (!city) {
    city = await getBoundary(key)
  }

  if (!city) {
    console.log('DEBUG: city not found ', key);
  }

  return city
}

const getURLParams = () => {
  const location = window?.location
  const urlParams = new URLSearchParams(location.search)
  const params = Object.fromEntries(urlParams)

  return params
}
const params = getURLParams()


const setURLLocation = (params, path = location.pathname, shouldReplaceURL = false) => {
  const location = window?.location
  // Only set if different
  //console.log('DEBUG setURLLocation ', setURLParams)
  //console.log('DEBUG: why was url location set ', params, userChangedMap);

  let pagePath = path

  // Featured page routing
  let setActivitiesParam = true
  const isFeatured = page.includes('featured')
  if (isFeatured && setActivitiesParam) {
    // Safely destructure first item
    const { activities, ...otherParams } = params;
    const [activity] = params?.activities || []
    pagePath = `/featured/${activity ? activity : ''}`
    params = otherParams
  }

  // TODO: only set params that are set and different than the default
  Object.keys(params).forEach(key => {
    let newValue = params[key]
    let defaultValue = defaultValues[key]

    // Convert to boolean
    if (newValue === 1 || newValue === 0) {
      newValue = Boolean(newValue)
    }

    // If the value is the same as the default, remove it
    const isDefault = isEqual(newValue, defaultValue)

    // Or if undefined or empty, remove it
    const isEmpty = newValue == undefined || newValue == '' || newValue == null || newValue == []

    if (isEmpty || isDefault) {
      delete params[key]
    }

  })

  // TODO: setURLParams is a confusing name,
  // because it doesn't set state; it's a boolean to store data in the URL or not
  let queryParams = queryString.stringify(params, { skipNull: true })
  window.history.pushState(null, null, `?${queryParams}`)
}



// 🏪 Basic example of zustand
export const useBearStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  removeAllBears: () => set({ bears: 0 }),
}))


// 🏪 Page Store
// State related to app page/screen context, user agent, etc.
const hasURLParams = params && Object.keys(params).length > 0
const detectMobile = useMobileDetect()
const isAndroid = detectMobile.isAndroid()
const isIos = detectMobile.isIos()
const isMobile = detectMobile.isMobile()
const isDesktop = detectMobile.isDesktop()

const selectedSection = params.section ? params.section : null

// Note: Tablet are detected as phone, so this fixes that
const isTablet = typeof document !== 'undefined'
  ? document?.body?.clientWidth < 880 && document?.body?.clientWidth > 500
  : false
const isPhone = isMobile && (isAndroid || isIos) && !isTablet
const isSSR = detectMobile.isSSR()

const isClient = typeof window !== 'undefined'

const cardOrInitial = params.cardOr ? params.cardOr : defaultValues.cardOr
const placeLayoutInitial = params.placeLayout ? params.placeLayout : defaultValues.placeLayout
const themeInitial = params.theme ? params.theme : null
const height = typeof document !== 'undefined' ? document?.body?.clientHeight : null
const width = typeof document !== 'undefined' ? document?.body?.clientWidth : null

export const pageStore = create((set) => ({
  // Getters
  cardOr: cardOrInitial,
  embedded: params.embedded && params.embedded == '1',
  hasParams: hasURLParams,
  height: height,
  isAndroid: isAndroid,
  isActive: false,
  isClient: isClient,
  isIos: false,
  isDesktop: isDesktop,
  isMobile: isMobile,
  isPhone: isPhone,
  isServer: false,
  isTablet: isTablet,
  pageHasMap: false,
  placeLayout: placeLayoutInitial,
  section: selectedSection,
  sectionIndex: 0,
  theme: params.theme ? params.theme : null,
  width: width,
  // Setters
  setIsAndroid: (isAndroid) => set({ isAndroid }),
  setIsActive: (isActive) => set({ isActive }),
  setIsClient: (isClient) => set({ isClient }),
  setEmbedded: (embedded) => set({ embedded }),
  setHeight: (height) => set({ height }),
  setIsIos: (isIos) => set({ isIos }),
  setIsDesktop: (isDesktop) => set({ isDesktop }),
  setIsMobile: (isMobile) => set({ isMobile }),
  setIsPhone: (isPhone) => set({ isPhone }),
  setIsServer: (isServer) => set({ isServer }),
  setIsTablet: (isTablet) => set({ isTablet }),
  setPageHasMap: (pageHasMap) => set({ pageHasMap }),
  setWidth: (width) => set({ width }),
}))


// 🏪 Filter Store
// State related to filter options and current selections
// Read defaults from URL params
// TODO: what about if the page context also tries to set the value?
const activitiesInitial = params.activities ? [].concat(params.activities) : []
const citiesInitial = params.cities
  ? [].concat(params.cities)
  : params.locations
    ? [].concat(params.locations)
    :[]
const dateRangeInitial = params.dateRange ? params.dateRange : 'month'
const startDateInitial = params.startDate ? params.startDate : null
const endDateInitial = params.endDate ? params.endDate : null
const editorialCategoryInitial = params.editorial_category ? params.editorial_category : null
const minZoomInitial = params.minZoom ? parseInt(params.minZoom) : defaultValues.minZoom
const searchInitial = params.search ? params.search : null
const tagsInitial = params.tags ? [].concat(params.tags) : []
const vibesInitial = params.vibes ? [].concat(params.vibes) : []
const radiusInitial = params.radius
  ? parseFloat(params.radius)
  : params.r
    ? parseFloat(params.r)
    : 12
const showCatsInitial = params.showCats ? params.showCats == '1' : true
const showEmptyInitial = params.showEmpty ? params.showEmpty == '1' : false
const showDateRangeInitial = params.showDateRange ? params.showDateRange == '1' : false
const showFiltersInitial = params.showFilters ? params.showFilters == '1' : true
const showSearchInitial = params.showSearch ? params.showSearch == '1' : true
const showTagsInitial = params.showTags ? params.showTags == '1' : false
const showVibesInitial = params.showVibes ? params.showVibes == '1' : true
const zoomInitial = params.zoom ? parseInt(params.zoom) : 12

const initialFilters = {
  activities: activitiesInitial,
  categories: [],
  cities: citiesInitial,
  dateRange: dateRangeInitial,
  dateStart: startDateInitial,
  dateEnd: endDateInitial,
  editorial_category: editorialCategoryInitial,
  search: searchInitial,
  tags: tagsInitial,
  vibes: vibesInitial,
}

export const filterStore = create((set, get) => ({
  // Getters
  activitiesCurrent: activitiesInitial, // TODO: rename to categories
  categoriesTop: [],
  citiesCurrent: [],
  dateRangeCurrent: dateRangeInitial,
  dateStart: startDateInitial,
  dateEnd: endDateInitial,
  locationsTop: [],
  editorialCategory: editorialCategoryInitial,
  filters: initialFilters,
  hasFilters: false,
  hasActivitiesFilter: false,
  hasCitiesFilter: false,
  hasSearchFilter: false,
  hasTagsFilter: false,
  hasVibesFilter: false,
  relatedVibes: [],
  searchCurrent: null,
  // Accept either radius or r from the URL para
  searchRadius: radiusInitial,
  shouldSetURLParams: true,
  showDateRange: showDateRangeInitial,
  showCats: showCatsInitial,
  showFilters: showFiltersInitial,
  showEmpty: showEmptyInitial,
  showSearch: showSearchInitial,
  showTags: showTagsInitial,
  showVibes: showVibesInitial,
  tagsCurrent: tagsInitial,
  tagsTop: [],
  vibesCurrent: vibesInitial,
  vibesTop: [],
  // Setters
  setActivitiesCurrent: (activitiesCurrent) => set({ activitiesCurrent }),
  setCategoriesTop: (categoriesTop) => set({ categoriesTop }),
  setCitiesCurrent: (citiesCurrent) => set({ citiesCurrent }),
  setDateRangeCurrent: (dateRangeCurrent) => set({ dateRangeCurrent }),
  setFilters: (filtersNew) => {
    const params = getURLParams()
    const paramsNew = { ...params, ...filtersNew }
    const paramsChanged = !isEqual(paramsNew, params)
    const filtersCurrent = get().filters
    const shouldSetURLParams = get().shouldSetURLParams
    const filtersChanged = !isEqual(filtersNew, filtersCurrent)
    const emptyParams = params == ''

    if (filtersChanged) {
      set({ filters: filtersNew })
    }

    set({ hasFilters: filtersChanged && !emptyParams })
    set({ hasActivitiesFilter: filtersNew.activities && filtersNew.activities.length > 0 })
    set({ hasCitiesFilter: filtersNew.cities && filtersNew.cities.length > 0 })
    set({ hasSearchFilter: filtersNew.search && filtersNew.search.length > 0 })
    set({ hasTagsFilter: filtersNew.tags && filtersNew.tags.length > 0 })
    set({ hasVibesFilter: filtersNew.vibes && filtersNew.vibes.length > 0 })
    if (shouldSetURLParams && !emptyParams) setURLLocation(paramsNew, paramsChanged)
  },
  setEditorialCategory: (editorialCategory) => set({ editorialCategory }),
  setLocationsTop: (locationsTop) => set({ locationsTop }),
  setSearchCurrent: (searchCurrent) => set({ searchCurrent }),
  setTagsCurrent: (tagsCurrent) => set({ tagsCurrent }),
  setTagsTop: (tagsTop) => set({ tagsTop }),
  setVibesCurrent: (vibesCurrent) => set({ vibesCurrent }),
  setVibesTop: (vibesTop) => set({ vibesTop }),
  setHasFilters: (hasFilters) => set({ hasFilters }),
  setHasActivitiesFilter: (hasActivitiesFilter) => set({ hasActivitiesFilter }),
  setHasCitiesFilter: (hasCitiesFilter) => set({ hasCitiesFilter }),
  setHasSearchFilter: (hasSearchFilter) => set({ hasSearchFilter }),
  setHasTagsFilter: (hasTagsFilter) => set({ hasTagsFilter }),
  setHasVibesFilter: (hasVibesFilter) => set({ hasVibesFilter }),
  setSearchRadius: (searchRadius) => set({ searchRadius }),
  setDateStart: (dateStart) => set({ dateStart }),
  setDateEnd: (dateEnd) => set({ dateEnd }),
  setShowCats: (showCats) => set({ showCats }),
  setShowDateRange: (showDateRange) => set({ showDateRange }),
  setShowFilters: (showFilters) => set({ showFilters }),
  setShowSearch: (showSearch) => set({ showSearch }),
  setVibesRelated: (vibesRelated) => set({ vibesRelated }),
}))


// 🏪 Data state
// State related to data fetching, loading, and caching
const vibesAll = getVibes()

const topLevelCategories = allActivities.activityCategories.filter(category => {
  const level = parseInt(category.level)
  if (level <= 2) return true
})

export const dataStore = create((set, get) => ({
  // Getters
  activitiesAll: allActivities,
  getActivityDetails: (key) => {
    const activities = get().activitiesAll
    const activity = activities.find(activity => activity.key == key)
    return activity
  },
  boundaries: [],
  boundariesFetch: async () => {
    console.log('DEBUG: Fetching boundaries...');
    const boundaries_response = await getAllBoundaries()
    const boundaries_data = boundaries_response?.results || []
    set({ boundaries: boundaries_data })
  },
  categoriesAll: [],
  citiesAll: allCities,
  citiesCurrent: [],
  neighborhoodsAll: [],
  neighborhoodsCurrent: [],
  allStories: [],
  detailsLoading: false,
  events: [],
  eventCards: [],
  eventsLoading: false,
  eventsLoaded: false,
  eventsError: null,
  eventsTotal: 0,
  numEvents: 0,
  numPlaces: 0,
  numPlacesToShow: defaultValues?.numPlacesToShow,
  currentPlace: null,
  placeDetails: null,
  placeToAdd: null,
  places: [],
  placesLoading: true,
  placesLoaded: false,
  placesError: null,
  placesScrolling: false,
  placesShouldRefresh: false,
  placeSwiped: false,
  placesTotal: 0,
  posts: [],
  stories: [],
  storiesLoading: false,
  storiesLoaded: false,
  storiesError: null,
  storiesTotal: 0,
  tagsAll: [],
  topLevelCategories: topLevelCategories,
  vibesAll: vibesAll,
  getVibeDetails: (key) => {
    const vibes = get().vibesAll
    const vibe = vibes.find(vibe => vibe.key == key)
    return vibe
  },
  // Setters
  addToPlaces: (place) => {
    const placesCurrent = get().places
    const placesNew = placesCurrent.concat(place)
    set({ places: placesNew })
  },
  setCitiesAll: (citiesAll) => set({ citiesAll }),
  setCitiesCurrent: (citiesCurrent) => set({ citiesCurrent }),
  setDetailsLoading: (detailsLoading) => set({ detailsLoading }),
  setNeighborhoodsAll: (neighborhoodsAll) => set({ neighborhoodsAll }),
  setNeighborhoodsCurrent: (neighborhoodsCurrent) => set({ neighborhoodsCurrent }),
  setAllStories: (allStories) => set({ allStories }),
  setAllTags: (allTags) => set({ allTags }),
  setVibesAll: (vibesAll) => set({ vibesAll }),
  setEvents: (events) => set({ events }),
  setEventCards: (eventCards) => set({ eventCards }),
  setEventsLoading: (eventsLoading) => set({ eventsLoading }),
  setEventsLoaded: (eventsLoaded) => set({ eventsLoaded }),
  setEventsError: (eventsError) => set({ eventsError }),
  setEventsTotal: (eventsTotal) => set({ eventsTotal }),
  setNumEvents: (numEvents) => set({ numEvents }),
  setNumPlaces: (numPlaces) => set({ numPlaces }),
  setPlaceCurrent: (placeCurrent) => {
    // Keep as consistent object
    // Parse Geojson to flat object
    const is_geojson = placeCurrent?.geometry?.type == 'Point'

    const place_properties = is_geojson
      ? placeCurrent.properties
      : placeCurrent

    const place = placeCurrent
      ? {
          ...place_properties,
          geometry: placeCurrent?.geometry,
          id: placeCurrent?.id,
          key: placeCurrent.id,
          slug: place_properties.slug
        }
      : null

    return set({ placeCurrent: place })
  },
  setPlaceToAdd: (placeToAdd) => set({ placeToAdd }),
  setPlaces: (places) => set({ places }),
  setPlacesLoading: (placesLoading) => set({ placesLoading }),
  setPlacesLoaded: (placesLoaded) => set({ placesLoaded }),
  setPlacesError: (placesError) => set({ placesError }),
  setPlacesShouldRefresh: (placesShouldRefresh) => set({ placesShouldRefresh }),
  setPlacesSwiped: (placeSwiped) => set({ placeSwiped }),
  setPlacesScrolling: (placesScrolling) => set({ placesScrolling }),
  setPlacesTotal: (placesTotal) => set({ placesTotal }),
  setPosts: (posts) => set({ posts }),
  setStories: (stories) => set({ stories }),
  setStoriesLoading: (storiesLoading) => set({ storiesLoading }),
  setStoriesLoaded: (storiesLoaded) => set({ storiesLoaded }),
  setStoriesError: (storiesError) => set({ storiesError }),
  setStoriesTotal: (storiesTotal) => set({ storiesTotal }),
}))


// 🏪 Location state
// State about the app or user's current location
import { getLocation } from 'components/utils/getLocation'
// Determine current location based on hierarchy:
// 1. User Location (if they updated it)
// 2. Initial location is set by page or param (will already be set)
// 3. City Location (if set)
// 4. User's predicted location (fallback)

// TODO: Move to defaults
const currentLocationDefault = {
  latitude: 37.8125262,
  longitude: -122.3054825,
  centerpoint: [-122.3054825, 37.8125262],
  fromUser: false,
  userRequested: false,
  centerpoint: [100, 30]
}

let hasLocation = false
const hasCoords = params.latitude && params.longitude
const hasCityParam = citiesInitial && citiesInitial.length > 0
const hasLocationFromSlug = false

let cityInitial = null
if (hasCityParam) {
  const cityParamFirst = citiesInitial[0]
  let city = await getCity(cityParamFirst)

  if (city) {
    // TODO: why isn't the data already formatted like this?
    city.centerpoint = [city.location.longitude, city.location.latitude]
    city.latitude = city.location.latitude
    city.longitude = city.location.longitude
    city.location.fromUser = true
  }

  if (city) {
    cityInitial = city
  }
}


if (hasCoords || hasLocationFromSlug || hasCityParam) {
  hasLocation = true
}

const currentLocationInitial = hasCoords
  ? {
    latitude: parseFloat(params.latitude),
    longitude: parseFloat(params.longitude),
    fromUser: true,
    userRequested: false,
    centerpoint: [params.longitude, params.latitude],
  }
  : hasCityParam && cityInitial
    ? cityInitial
    : currentLocationDefault

const locationUser = hasCoords ? currentLocationInitial : null

export const locationStore = create((set, get) => ({
  allCities: [],
  cityCurrent: cityInitial,
  // Derive current location from currentCity?
  hasLocation: hasLocation,
  hasLocationFromPage: false,
  locationCurrent: currentLocationInitial,
  locationUser: locationUser,
  tryLocation: false,
  checkLocation: (state) => {
    const currentLocation = getLocation()
    if (currentLocation) {
      set({ currentLocation: currentLocation })
      set({ hasLocation : true})
    } else {
      set({ currentLocation: currentLocationDefault })
      set({ hasLocation: true })
    }
  },
  setCurrentCity: (cityCurrent) => {
    return set({ cityCurrent })
  },
  setLocationCurrent: (locationCurrent, shouldSetURL = false) => {
    set({ locationCurrent: locationCurrent })
    // Set URL state with location
    const params = getURLParams()

    if (shouldSetURL) {
      let newParams = {
        ...params,
        latitude: locationCurrent?.latitude,
        longitude: locationCurrent?.longitude,
        //radius: searchRadius,
        //zoom: currentZoom
      }
      setURLLocation(newParams)
    }
  },
  setHasLocation: (hasLocation) => set({ hasLocation: hasLocation }),
  setTryLocation: (tryLocation) => set({ tryLocation: tryLocation }),
}))


// 🏪 Map state
// State related to the map and map controls
const keepBoundsInitial = params.keepBounds ? params.keepBounds == '1' : defaultValues.keepBounds
const fitBoundsInitial = params.fitBounds ? params.fitBounds == '1' : defaultValues.fitBounds
const fitMarkersInitial = params.fitMarkers ? params.fitMarkers == '1' : defaultValues.fitMarkers
const shouldShuffleInitial = params.shouldShuffle ? params.shouldShuffle == '1' : defaultValues.shouldShuffle
const showBoundaryInitial = params.showBoundary ? params.showBoundary == '1' : defaultValues.showBoundary
const showAddressInitial = params.showAddress ? params.showAddress == '1' : defaultValues.showAddress
const showDescriptionInitial = params.showDesc ? params.showDesc == '1' : defaultValues?.showDescription
const showNoteInitial = params.showNote ? params.showNote == '1' : defaultValues.showNote
const showAsInitial = params.showAs ? params.showAs : defaultValues.showAs
const showHeat = params.showHeat ? params.showHeat == '1' : defaultValues.showHeat
const showTitleInitial = params.showTitle ? params.showTitle == '1' : defaultValues.showTitle

export const mapStore = create((set, get) => ({
  // Map Getters
  cardStyle: defaultValues?.cardStyle, // TODO: rename to cardListStyle to be more clear
  fitBounds: fitBoundsInitial,
  fitMarkers: fitMarkersInitial,
  keepBounds: keepBoundsInitial,
  mapLocationCurrent: null,
  mapPos: defaultValues?.mapPos,
  mapTheme: defaultValues?.mapTheme,
  mapUpdateByUser: false,
  mapUpdated: false,
  minZoom: minZoomInitial,
  numPlacesToShow: defaultValues?.numPlacesToShow,
  numPlacesToStore: defaultValues?.numPlacesToStore,
  popup: null,
  shouldShuffle: shouldShuffleInitial,
  showBoundary: showBoundaryInitial,
  showAddress: showAddressInitial,
  showDescription: showDescriptionInitial,
  showAs: showAsInitial,
  showHeat: showHeat,
  showNote: showNoteInitial,
  showPopups: defaultValues?.showPopups,
  showTitle: showTitleInitial,
  showTransit: defaultValues?.showTransit,
  storyScroll: false,
  zoomCurrent: zoomInitial,
  zoomMin: 8,
  zoomMax: 20,
  // Map Setters
  setCurrentZoom: (currentZoom) => set({ currentZoom }),
  setMapLocationCurrent: (mapLocationCurrent) => set({ mapLocationCurrent }),
  setMapUpdated: (mapUpdated) => set({ mapUpdated }),
  setMapUpdateByUser: (mapUpdateByUser) => set({ mapUpdateByUser }),
  setPopup: (popup) => set({ popup }),
  setStoryScroll: (storyScroll) => set({ storyScroll }),
  setZoomCurrent: (zoomCurrent) => set({ zoomCurrent }),
}))